import React from "react";
import { Link } from "react-router-dom";
import "./header.scss";
import headerLogo from "../images/header_logo.png";
const data = require("../data/data.json");

class Header extends React.Component {
  render() {
    return (
      <div className="background">
        <div className="header-container">
          <div className="header-row">
            <div className="header-container-image">
              <img src={headerLogo} alt="header logo"></img>
            </div>

            <div className="header-container-title">
              <Link to={{ pathname: "/" }} style={{ textDecoration: "none" }}>
                <h2>Community Relief Program</h2>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
