import React, { Component } from "react";
import Header from "../components/header";
import Footer from "../components/footer.js";
import { Link, withRouter } from "react-router-dom";
import { createInvoice } from "../api/createInvoice";
import "./page-donate.scss";
import history from "../history";
const currencies = require("../data/currencies.json");

class ACHForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      email: "",
      name: "",
      currency: "usd",
    };

    this.emailUpdate = this.emailUpdate.bind(this);
    this.nameUpdate = this.nameUpdate.bind(this);
  }

  emailUpdate(event) {
    this.setState({ email: event.target.value });
  }

  nameUpdate(event) {
    this.setState({ name: event.target.value });
  }

  handleSubmit = async (event) => {
    if (this.state.name === "" || this.state.email === "") {
      this.setState({ processing: false });
      alert("Please provide your name and email.");
      event.preventDefault();
      return;
    }
    const { amount } = this.props;
    this.setState({ processing: true });
    event.preventDefault();
    const calculatedAmount =
      currencies[this.state.currency.toLowerCase()] * amount;

    const invoice = await createInvoice(
      calculatedAmount,
      this.state.currency,
      null,
      this.state.name,
      this.state.email
    );
    if (invoice.error) {
      this.setState({ processing: false });
      alert(invoice.error);
      return;
    } else {
      history.push({
        pathname: "/donation-thank-you-ach",
      });
    }
  };

  render() {
    return (
      <div>
        <Header />
        <center>
          <div className="header">
            Donate{" "}
            <span style={{ color: "#E05050" }}>${this.props.amount}</span> to{" "}
            <span style={{ color: "#E05050" }}>
            POT2 - August 2023 Meeting - Immigration Advocacy Support Center
            </span>
          </div>
        </center>
        <div className="donate-page-container">
          <div className="donate-page-container-title">
            <center>
              <form onSubmit={this.handleSubmit}>
                <div className="checkout-form">
                  <input
                    placeholder="Name*"
                    value={this.state.name}
                    onChange={this.nameUpdate}
                  />
                  <input
                    placeholder="Email*"
                    value={this.state.email}
                    onChange={this.emailUpdate}
                  />
                </div>
                <hr />
                <button type="submit" disabled={this.state.processing}>
                  {this.state.processing ? "Processing..." : "Donate Now"}
                </button>
              </form>
            </center>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

class DonateACHForm extends Component {
  render() {
    return (
      <ACHForm
        amount={this.props.location.state.amount}
        currency={this.props.location.state.currency}
      />
    );
  }
}

export default withRouter(DonateACHForm);
